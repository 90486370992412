@import "../../Common/commonStyle/commonStyle.scss";
.appearance-container {
  .appearance {
    img {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding: 1rem;
      background-color: #d9d9d9;
    }
    .title {
      margin: 0px;
      padding: 0.3rem 0rem;
      border-radius: 8px;
      text-align: center;
      background-color: white;
      width: 100%;
      font-weight: 400;
      color: $grey;
    }
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    margin-right: 1rem;
  }
  .selected {
    border-radius: 8px;
    border: 1px solid $primaryColor;
    margin-right: 1rem;
    .title {
      margin: 0px;
      padding: 0.3rem 0rem;
      border-radius: 8px;
      text-align: center;
      background-color: white;
      width: 100%;
      font-weight: 400;
      color: black;
    }
  }
}
