@import "../../Common/commonStyle/commonStyle.scss";
@import "../Filter/styles.scss";

.heading-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pending {
    align-items: center;
    position: absolute;
    left: 20rem;
    top: 1rem;
    p {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  padding: 1rem;
  h2 {
    font-family: "Nexa";
  }

  .options-container {
    display: flex;

    i {
      margin: 0rem 0.5rem;
      border: 1px solid $lightGrey;
    }
    button {
      margin-right: 0.2rem;
      margin-left: 0.2rem;
    }
    .primary {
      background-color: $primaryColor;
    }
    p {
      color: $primaryColor;
      margin-top: 0rem;
      margin-left: 0.5rem;
    }
    .filter {
      position: relative;
    }
    .white {
      color: white;
    }
    img {
      margin-top: -0.8rem;
    }
    span {
      display: flex;
      align-items: center;
    }
    .default {
      margin-top: 0.7rem;
    }
  }
}
