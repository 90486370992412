@import "../Common/commonStyle/commonStyle.scss";
.dashboard-container {
  text-align: left;
  .table {
    border-radius: 18px;
    margin: 0rem 1.2rem;
    z-index: -1;
    width: 96%;
    table {
      th {
        font-family: "Nexa";
        text-wrap: nowrap;
        background-color: $primaryColor;
        color: white;
        font-weight: 500;
        z-index: 0;
      }
      tbody {
        tr {
          td {
            p {
              margin: 0;
              text-transform: capitalize;
              max-width: 100%;
            }
            .address {
              max-width: 12rem;
            }
            .active {
              background-color: $green;
              border-radius: 4px;
              text-align: center;
              color: white;
            }
            .reject {
              background-color: $red;
              border-radius: 4px;
              text-align: center;
              color: white;
            }
            .draft {
              background-color: $grey;
              text-align: center;
              border-radius: 4px;
              color: white;
            }
            .approved {
              text-align: center;
              color: $green;
            }
            .pending {
              text-align: center;
              color: $darkGrey;
            }
          }
        }
      }
    }
  }
}
