@import "../../Common/commonStyle/commonStyle.scss";
.userdetail-container {
  margin: 0rem 1rem;
  .profile {
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
  }
  .name {
    margin-left: 1rem;
    h3 {
      margin: 0rem;
      text-align: left;
      text-transform: capitalize;
    }
    p {
      margin: 0rem;
      color: $grey;
      text-align: left;
    }
  }
  .options-bar {
    margin-top: 2rem;
    width: 100%;
    border-radius: 8px;
    background-color: rgba($color: $primaryColor, $alpha: 0.1);
    p {
      cursor: pointer;
      margin: 0.3rem;
      padding: 0.4rem 1rem;
      border-radius: 8px;
      color: $primaryColor;
    }
    .selected {
      color: white;
      background-color: $primaryColor;
    }
  }
  .info-container {
    margin-right: 3rem;
    .heading {
      color: $grey;
      text-align: left;
    }
    .detail {
      text-align: left;
    }
  }
  .table {
    border-radius: 18px;
    margin: 1rem 0rem;

    // width: 96%;
    table {
      th {
        font-family: "Nexa";
        text-wrap: nowrap;
        background-color: $primaryColor;
        color: white;
        font-weight: 500;
        z-index: 0;
      }
      tbody {
        tr {
          td {
            p {
              text-transform: capitalize;
              max-width: 100%;
              margin: 0;
            }
            .address {
              max-width: 12rem;
            }
            .active {
              background-color: $green;
              border-radius: 4px;
              text-align: center;
              color: white;
            }
            .draft {
              background-color: $grey;
              text-align: center;
              border-radius: 4px;
              color: white;
            }
            .approved {
              text-align: center;
              color: $green;
            }
            .pending {
              text-align: center;
              color: $darkGrey;
            }
          }
        }
      }
    }
  }
}
