@import "../commonStyle/commonStyle.scss";
.switch {
  margin-left: 0.5rem;
}
.primary {
  background-color: $primaryColor;
}
.upload {
  background-color: $primaryColor;
  cursor: pointer;
  color: white;
  padding: 0.3rem 1rem;
  border-radius: 5px;
}
.icon-container {
  position: relative;
  height: 4.5rem;
  width: 4.5rem;
  .icon {
    height: 4.5rem;
    width: 4.5rem;
  }
  .delete {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    top: -1rem;
    right: -1rem;
    background-color: $red;
  }
}
.margin {
  margin-left: 1rem;
}
