@import "../../../Common/commonStyle/commonStyle.scss";
.request-heading {
  .options-container {
    display: flex;
    button {
      margin-right: 0.2rem;
      margin-left: 0.2rem;
    }
    p {
      color: $primaryColor;
      margin-top: 0rem;
      margin-left: 0.5rem;
    }
    .white {
      color: white;
    }
    span {
      display: flex;
      align-items: center;
    }
    .default {
      margin-top: 0.7rem;
    }
  }
  button {
    p {
      margin: 0;
    }
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .approve {
    background-color: #83c372;
    img {
      margin-right: 0.2rem;
    }
  }
  .reject {
    background-color: #e54e4e;
    img {
      margin-right: 0.2rem;
    }
  }
  i {
    margin: 0rem 0.5rem;
    border: 1px solid $lightGrey;
  }
  .request {
    color: $grey;
  }
}
