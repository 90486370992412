@import '../../Common/commonStyle/commonStyle.scss';
.filter-container {
    border-radius: 8px;
    padding: 0.5rem;
    border: 1px solid $lightGrey;
    background: white;
    box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.1);
    position: absolute;
    width: 18rem;
    right: 0;
    max-height: 30rem;
    margin-top:1rem;
    overflow-y: auto;
    z-index: 9;
    .heading {
      color: black;
    }
    .reset {
      margin-top: -0.8rem;
      color: $grey;
      text-decoration: underline;
      .cross{
        padding-top:.85rem;
      }
    }
  }