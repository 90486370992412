@import "../Common/commonStyle/commonStyle.scss";
.forgot-password-container {
  .main-container {
    align-items: start;
    .left-container {
      background-color: $lightGrey;
      margin-top: -9rem;
      padding-top: 10rem;
      z-index: -1;
      padding-left: 5rem;
      padding-right: 6rem;
      text-align: left;
      h1 {
        font-size: 1.5rem;
        font-weight: bold;
      }
      img {
        width: 100%;
      }
    }

    .right-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      padding-left: 4rem;
      padding-right: 6rem;
      h1 {
        font-weight: 700;
      }
      h5 {
        margin-top: -1rem;
        font-size: 0.9rem;
        font-weight: 400;
        color: $grey;
        text-align: left;
        padding-right: 3rem;
      }
      .email-example {
        margin-top: -1rem;
      }
      .form-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        p {
          text-align: left;
          margin-bottom: 0rem;
        }
        .resend {
          margin-top: -0.5rem;
          margin-bottom: 1rem;
          a {
            color: $primaryColor;
          }
        }
        button {
          width: 100%;
          background-color: $primaryColor;
        }
      }
      .sign-up {
        margin-top: 0;
        width: 100%;
        a {
          color: $primaryColor;
          text-decoration: underline;
        }
      }
    }
  }
}
