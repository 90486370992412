@import "../../Common/commonStyle/commonStyle.scss";
.selected-step-container {
  display: flex;
  margin: -0.8rem 1.2rem;
  justify-content: space-between;
  .step {
    display: flex;
    align-items: center;
    p {
      margin: 0rem;
      width: 1.3rem;
      margin-right: 0.2rem;
      border-radius: 100px;
      text-align: center;
    }
    h5 {
      margin: 0rem;
      font-weight: 400;
      text-wrap: nowrap;
    }
    .selected {
      background-color: $primaryColor;
      color: white;
    }
    .unselected {
      background-color: rgba($color: $primaryColor, $alpha: 0.1);
    }
    .unselected-heading {
      color: $darkGrey;
    }
    .selected-heading {
      color: $primaryColor;
    }
  }
  .straight-line {
    width: 100%;
    height: 0px;
    margin-top: 0.7rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid $primaryColor;
  }
}
