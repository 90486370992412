@import "../Common/commonStyle/commonStyle.scss";

.new-project-container {
  text-align: left;
  .main-image {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 98%;
  }
  .secondary-images {
    position: relative;
    .other-image {
      width: 100%;
      margin-left: 1.3rem;
    }
    .extra-image {
      position: absolute;
      bottom: 0;
      left: 1.3rem;
      width: 100%;
      background-color: $primaryColor;
      border-radius: 6px;
      p {
        position: absolute;
        bottom: 0;
        left: 45%;
        top: 25%;
        color: white;
        font-size: 24px;
      }
      img {
        opacity: 0.2;
        width: 100%;
      }
    }
  }
  .detail {
    margin-left: 1.2rem;
    margin-right: 10rem;
    text-align: justify;
    p {
      color: $grey;
    }
    img {
      width: 100%;
    }
    span {
      margin-right: 0.3rem;
      display: flex;
      align-items: center;
      background-color: $lightBackground;
      padding: 0.1rem 1.1rem;
      border-radius: 100px;

      img {
        margin-right: 0.2rem;
        width: 1.2rem;
        height: 1.2rem;
      }
      p {
        margin: 0;
        font-size: 14px;
        color: $primaryColor;
      }
    }
    .location {
      background-color: $lightGrey;
      border-radius: 5px;
      margin-right: 0.8rem;
      margin-bottom: 0.5rem;
      padding: 0.5rem 1rem;
    }
  }
}
