@import "../Common/commonStyle/commonStyle.scss";
.message-container {
  hr {
    margin: 0rem 1rem;
  }
  .heading {
    // background-color: $primaryColor;
    // color: white;
    position: absolute;
    width: 100%;
    h3 {
      margin: 0;
      padding: 0.5rem;
    }
  }
  overflow: hidden;
  height: 90vh;

  // border: 1px solid $grey;
  border-top: 0;
  .left-side {
    text-align: left;
    position: relative;

    height: 100%;
    overflow-y: auto;
    // border: 1px solid $grey;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    .project {
      margin: 0.4rem 0.5rem;
      // border: 1px solid $grey;
      border-radius: 5px;
      padding: 0.2rem 0.4rem;
      cursor: pointer;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      .date {
        color: $grey;
        margin-right: 1rem;
        font-size: 12px;
        margin-top: -0.5rem;
        text-align: right;
      }
    }
    .selected {
      color: $primaryColor;
      background-color: rgba($color: $primaryColor, $alpha: 0.1);
    }
    // background-color: aliceblue;
  }
  .comment-container {
    text-align: left;
    overflow-y: auto;
    .comments {
      position: relative;
      margin: 0.5rem;
      max-width: 18rem;
      margin-bottom: 1rem;
      z-index: 0;
      padding: 0.1rem 0.4rem;
      border-radius: 5px;
      color: $primaryColor;
      background-color: #e0d5e9;
      .date {
        font-size: 10px;
        color: $grey;
      }
      .arrow {
        position: absolute;
        height: 1rem;
        width: 1rem;
        // z-index: -1;
        background-color: #e0d5e9;
        bottom: -0.3rem;
        left: 0.5rem;
        transform: rotate(60deg);
      }
    }
    .reply {
      // z-index: -1;
      position: relative;
      margin: 0.5rem;
      max-width: 18rem;
      margin-bottom: 1rem;

      padding: 0.1rem 0.4rem;
      border-radius: 5px;

      background-color: #ddd;
      .date {
        font-size: 10px;
        color: $grey;
      }
      .arrow {
        position: absolute;
        height: 1rem;
        width: 1rem;
        // z-index: -1;
        background-color: #ddd;
        bottom: -0.3rem;
        right: 0.5rem;
        transform: rotate(30deg);
      }
    }
  }
}
