@import "../../Common/commonStyle/commonStyle.scss";
.card-view-container {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-align: center;
  text-transform: capitalize;
  .card-tile {
    padding: 0.5rem;
    .type {
      color: $green;
      background-color: $greenBackground;
      font-size: 10px;
      border-radius: 100px;
      padding: 0.1rem 0.8rem;
    }
    .option {
      color: $green;
      background-color: $greenBackground;
      font-size: 10px;
      border-radius: 100px;
      padding: 0.1rem 0.8rem;
    }
    .card {
      position: relative;
      border-radius: 12px;
      background-color: $lightGrey;
      border: 1px solid $grey;
      padding: 1.5rem 0.5rem;
      img {
        width: 100%;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }
      h4 {
        margin-top: 0.3rem;
        margin-left: 0.3rem;
      }
      span {
        font-size: 12px;
        height: 1.4rem;
        padding: 2rem 1rem;
        color: $grey;
        border-radius: 100px;
      }
      p {
        margin-top: -0.5rem;
        font-size: 0.642rem;
        color: $grey;
        margin-left: 0.3rem;
      }
      .status {
        position: absolute;
        top: 1rem;
        left: 0.5rem;
        background-color: $greenBackground;
        padding: 0rem 0.5rem;
        border-radius: 100px;
        color: $green;
        p {
          margin: 0rem;
        }
      }
    }
  }
}
