@import "../../Common/commonStyle/commonStyle.scss";
.page-2-container {
  // width: 100%;
  margin: 2rem 1.3rem;
  .margin {
    margin-left: 1rem;
  }
  .margin-top {
    margin-top: 2rem;
  }
  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    border: 1px dashed $primaryColor;
    background-color: rgba(102, 45, 145, 0.1);
    padding-top: 2rem;
    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    img {
      height: 3rem;
    }
    h3 {
      margin-bottom: 0.5rem;
    }
    p {
      margin-top: 0rem;
      color: $grey;
    }
    a {
      color: $primaryColor;
      text-decoration: underline;
    }
  }
  .uploaded-container {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    .image {
      position: relative;
      margin: 0.5rem;

      .delete {
        display: flex;
        justify-content: center;
        position: absolute;
        border-radius: 100px;
        background-color: $red;
        height: 1.5rem;
        width: 1.5rem;
        top: 0;
        right: 0;
      }
      img {
        border: 2px solid $primaryColor;
        border-radius: 8px;
        height: 10rem;
        width: 10rem;
        margin-right: 1rem;
        border-radius: 8px;
      }
    }
  }
}
