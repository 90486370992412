.main-container {
  .logo-container {
    margin-left: 5rem;
    padding-top: 0.5rem;
    height: 4rem;
    p {
      font-weight: 600;
      font-size: 1.1rem;
    }
    .logo-text {
      margin-top: 0rem;
      font-size: 2rem;
    }
    .logo {
      img {
        // padding-top: 7px;
        width: 3rem;
        height: 3rem;
      }

      margin-right: 1rem;
    }
  }
  .options-container {
    display: flex;
    margin-right: 5rem;
    a {
      font-size: 3;
      margin-right: 1rem;
      color: #616366;
      text-decoration: none;
    }
  }
}
