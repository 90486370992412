@import "../../Common/commonStyle/commonStyle.scss";
.edit-card-container {
  img {
    height: 5rem;
    width: 5rem;
  }
  h4 {
    margin-bottom: 0.2rem;
  }
  p {
    margin: 0rem;
  }
}
