@import "../commonStyle/commonStyle.scss";
.accordian-container {
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid $lightGrey;
  .heading {
    background-color: $lightGrey;
    padding-right: 0.5rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    p {
      margin: 0.4rem;
      color: black;
    }
  }
  .items {
    margin-left: 0.5rem;
    p {
      margin: 0.4rem;
      color: $darkGrey;
    }
  }
}
