@import "../Common/commonStyle/commonStyle.scss";
.signup-container {
  .main-container {
    align-items: start;
    .left-container {
      background-color: $lightGrey;
      margin-top: -10rem;
      padding-top: 10rem;
      z-index: -1;
      padding-left: 5rem;
      padding-right: 6rem;
      text-align: left;
      h1 {
        font-size: 1.4rem;
        font-weight: bold;
      }
      img {
        width: 100%;
      }
    }

    .right-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 2rem;
      padding-right: 6rem;
      h1 {
        margin: 0;
        font-weight: 700;
      }
      h5 {
        margin-top: -0.2rem;
        font-size: 0.9rem;
        font-weight: 400;
        color: $grey;
        text-align: left;
        padding-right: 3rem;
      }
      .email-example {
        margin-top: -1rem;
        margin-bottom: 1.5rem;
      }
      .form-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        p {
          margin-top: -0.8rem;
          text-align: left;
          margin-bottom: -0.2rem;
        }
        .remember-me-container {
          margin-bottom: 1.5rem;
          a {
            color: $primaryColor;
          }
          p {
            margin-top: 0;
            margin-left: 1rem;
          }
        }
        button {
          width: 100%;
        }
      }
      .sign-up {
        margin-top: -1rem;
        width: 100%;
        a {
          text-decoration: underline;
          color: $primaryColor;
        }
      }
      .resend {
        margin-top: -1rem;
        margin-bottom: 2rem;
        width: 100%;
        a {
          text-decoration: underline;
          color: $primaryColor;
        }
      }
    }
  }
}
