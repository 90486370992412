@import "../../Common/commonStyle/commonStyle.scss";
.delete-account-container {
  input {
    width: 25rem;
  }
  h4 {
    margin-bottom: -0.8rem;
  }
  .cancel {
    border-color: $grey;
    margin-left: 1rem;
    color: $grey;
  }
}
