@import "../../Common/commonStyle/commonStyle.scss";
.page-1-container {
  padding-right: 10rem;
  .width {
    width: 100%;
  }
  .margin {
    margin-left: 1rem;
  }
  h4 {
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 0.3rem;
    font-weight: 500;
  }
  margin: 1rem 2rem 3rem 1.2rem;
  .radio {
    border-radius: 5px;
    border: 1px solid $grey;
    color: $grey;
    padding: 0.1rem 0.5rem;
  }
  .primary {
    background-color: $primaryColor;
  }
  .selected-option {
    border-color: $primaryColor;
    background-color: rgba($color: $primaryColor, $alpha: 0.1);
    color: $primaryColor;
  }
  .benefits {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .benefit {
      color: $primaryColor;
      background-color: rgba($color: $primaryColor, $alpha: 0.1);
      margin-right: 0.6rem;
      border-radius: 100px;
      padding: 0.2rem 1rem;
      margin-bottom: 0.5rem;
    }
    .selected-benefit {
      color: white;
      background-color: $primaryColor;
    }
  }
  .map {
    width: 100%;
    height: 25rem;
  }
}
