.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}
.post-login-header-container {
  p {
    font-family: "Nexa";
  }
  span {
    font-family: "Nexa";
  }
  display: flex;
  border: solid #edeff2;
  background-color: white;
  border-width: 0px 0px 2px 0px;
  .search-notification-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .search-bar {
      max-width: 15rem;
      border: 1px solid #edeff2;
    }
  }
  .profile-container {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    img {
      width: 2rem;
      height: 2rem;
    }
    .seperator {
      border: 1px solid #edeff2;
      height: 1.8rem;
      margin-right: 1rem;
    }
    .name-email-container {
      margin: 0rem 1rem;
      p {
        margin-top: 0rem;
        text-wrap: nowrap;
        margin-bottom: 0rem;
        font-size: 14px;
      }
      span {
        margin-top: 0rem;
        color: #616366;
        font-size: 12px;
      }
    }
  }
}
