@import "../commonStyle/commonStyle.scss";
.header-sticky {
  position: sticky;
  top: 0;
  // z-index: 1029;
}
.side-nav-container {
  border-style: solid;
  padding-left: 1.5rem;
  height: 97vh;
  padding-top: 1rem;
  border-width: 0px 0.1rem 0px 0px;
  border-color: #d2d5d9;
  .heading {
    margin-top: -0.5rem;
  }
  img {
    width: 2.8rem;
    height: 2.8rem;
  }
  h1 {
    font-size: 2.2rem;
    font-weight: 600;
    margin: 0rem;
  }
  .cursor {
    cursor: pointer;
  }
  .selected {
    background-color: $primaryColor;
    border-radius: 8px;
    margin-right: 0.4rem;

    p {
      color: white;
    }
    .chevron {
      rotate: 180deg;
    }
  }
  .selected-child {
    font-weight: 700;
  }
  .option-container {
    padding-top: 0.5rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    cursor: pointer;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
    p {
      font-family: "Nexa";
      margin-top: 0.1rem;
      margin-left: 0.5rem;
    }
  }
}
