@import "../Common/commonStyle/commonStyle.scss";
.request-list-container {
  margin: 1.2rem;
  .table {
    border-radius: 18px;
    table {
      th {
        font-family: "Nexa";
        text-wrap: nowrap;
        background-color: $primaryColor;
        color: white;
        z-index: 0;
        font-weight: 500;
      }
      tbody {
        tr {
          td {
            p {
              margin: 0;
              text-transform: capitalize;
              max-width: 100%;
            }
            .address {
              max-width: 12rem;
            }

            .approved {
              text-align: center;
              color: $green;
            }
            .rejected {
              text-align: center;
              color: $red;
            }
          }
        }
      }
    }
  }
}
