@import "../../Common/commonStyle/commonStyle.scss";
.password-container {
  width: 25rem;
  h4 {
    margin-bottom: -0.8rem;
  }
  .requirement {
    color: black;
  }
}
