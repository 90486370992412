@import "../Common/commonStyle/commonStyle.scss";
.container {
  display: flex;
  flex-direction: column;
  .main-container {
    align-items: flex-start;
    .left-container {
      padding-top: 6rem;
      padding-left: 5rem;
      h1 {
        font-size: 2rem;
        text-align: left;
        font-weight: 700;
      }
      .login {
        display: flex;
        align-items: flex-start;
        button {
          background-color: $primaryColor;
          color: white;
          text-transform: capitalize;
        }
      }
      .signup {
        display: flex;
        align-items: flex-start;
        button {
          color: $primaryColor;
          border: solid;
          border-width: 1px;
          border-color: $primaryColor;
          text-transform: capitalize;
        }
      }
    }

    .right-container {
      padding-top: 3rem;
      padding-right: 5rem;
      img {
        width: 95%;
      }
    }
  }
}
