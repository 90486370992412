@import "../../Common/commonStyle/commonStyle.scss";
.page-3-container {
  margin: 1.3rem;
  .primary {
    background-color: $primaryColor;
  }
  .card {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $lightGrey;
    border: 1px solid $grey;
    border-radius: 5px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    p {
      color: $grey;
      margin: 0;
    }
    button {
      width: 100%;
    }
  }
}
