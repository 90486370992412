@import "../../Common/commonStyle/commonStyle.scss";
.grid-view-container {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  .no {
    width: 100%;
    text-align: center;
    color: $grey;
  }
  .project-tile {
    padding: 0.5rem;
    position: relative;
    .checkbox {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 99;
    }

    .card {
      position: relative;
      border-radius: 12px;
      border: 1px solid $primaryColor;
      height: 10rem;
      text-overflow: ellipsis;
      img {
        width: 100%;
        height: 5rem;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }
      h4 {
        color: $primaryColor;
        margin-top: 0.3rem;
        margin-left: 0.3rem;
      }
      span {
        margin-top: 0.3rem;
        font-size: 12px;
        height: 1.4rem;
        text-transform: capitalize;
        padding: 0rem 1rem;
        padding-top: 0.2rem;
        margin-right: 0.3rem;
        color: $primaryColor;
        border-radius: 100px;
        background-color: rgba($primaryColor, 0.1);
      }
      p {
        margin-top: -0.5rem;
        font-size: 0.642rem;
        color: $grey;
        text-transform: capitalize;
        margin-left: 0.3rem;
      }
      .status {
        position: absolute;
        top: 1rem;
        left: 0.5rem;
        text-transform: capitalize;
        background-color: $greenBackground;
        padding: 0rem 0.5rem;
        border-radius: 100px;
        color: $green;
        p {
          margin: 0rem;
        }
      }
    }
  }
}
