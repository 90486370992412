@import "../Common/commonStyle/commonStyle.scss";
.dashboard-container {
  padding: 0rem 1rem;
  .white {
    color: white;
  }
  .purple {
    color: $primaryColor;
  }
  .box {
    border: 1px solid $grey;
    padding: 0rem 1rem;
    border-radius: 8px;
  }
  .filters {
    margin-top: 1rem;
    p {
      margin: 0;
    }
    border: 1px solid $grey;
    border-radius: 5px;
    .filter {
      padding: 0.2rem 0.5rem;
      margin: 0.1rem;
    }
    .selected {
      border-radius: 5px;
      background-color: #e0d5e9;
      color: $primaryColor;
    }
  }
  .bar-graph {
    margin-top: 1rem;
    width: 44.3%;
    margin-right: 0.5rem;
    border: 1px solid $grey;
    border-radius: 8px;
    padding: 0rem 1rem;
  }
  .full {
    margin-top: 1rem;
    width: 92.7%;
    margin-right: 0.5rem;
    border: 1px solid $grey;
    border-radius: 8px;
    padding: 0rem 1rem;
  }
  .line-graph {
    margin-top: 1rem;
    width: 92.7%;
    border: 1px solid $grey;
    border-radius: 8px;
    padding: 0rem 1rem;
  }
}
