@import "../Common/commonStyle/commonStyle.scss";
.whole-screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.notification-container {
  .height {
    height: 25rem;
    overflow-y: auto;
  }
  position: absolute;
  border: 1px solid $grey;
  padding: 0rem 0.5rem;
  border-radius: 8px;

  right: 0;
  top: 2rem;
  background-color: white;
  overflow: hidden;
  width: 17rem;
  .notification {
    position: relative;
    background-color: rgba($primaryColor, 0.1);
    padding: 0.1rem 0.2rem;
    margin-bottom: 0.1rem;
    text-align: left;
    .date {
      font-size: 8px;
      margin-top: -0.5rem;
      text-align: right;
      color: $grey;
    }
    .new-notification {
      top: -6px;
      right: 8px;
      font-size: 10px;
      position: absolute;
      background-color: $primaryColor;
      color: white;
      padding: 0.1rem 0.2rem;
    }
  }
}
