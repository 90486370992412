@import "../../Common/commonStyle/commonStyle.scss";
.profile-container {
  img {
    height: 6rem;
    width: 6rem;
    border-radius: 100px;
  }

  .upload {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .form-container {
    margin-top: 1rem;
    p {
      margin: 0;
    }
  }
}
