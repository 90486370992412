@import "../../Common/commonStyle/commonStyle.scss";
.user-management-container {
  margin: 1.2rem;
  .table {
    border-radius: 18px;
    .email {
      text-transform: lowercase;
    }
    table {
      th {
        font-family: "Nexa";
        text-wrap: nowrap;
        background-color: $primaryColor;
        color: white;
        z-index: 0;
        font-weight: 500;
      }
      tbody {
        tr {
          td {
            p {
              margin: 0;
              text-transform: capitalize;
              max-width: 100%;
            }
            .address {
              max-width: 12rem;
            }
            .active {
              background-color: $green;
              border-radius: 4px;
              color: white;
            }
            .draft {
              background-color: $grey;
              text-align: center;
              border-radius: 4px;
              color: white;
            }
            .approved {
              text-align: center;
              color: $green;
            }
            .pending {
              text-align: center;
              color: $darkGrey;
            }
          }
        }
      }
    }
  }
}
