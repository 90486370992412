@import "../../Common/commonStyle/commonStyle.scss";
.setting-side-nav-container {
  margin-left: 1.5rem;
  cursor: pointer;
  .unselected {
    color: $grey;
  }
  .selected {
    color: $primaryColor;
    font-weight: 600;
  }
  .delete-account {
    color: $red;
  }
}
