@import "../Common/commonStyle/commonStyle.scss";
.card-container {
  text-align: left;
  .table {
    border-radius: 18px;
    margin: 0rem 1.2rem;

    width: 96%;
    table {
      th {
        font-family: "Nexa";
        text-wrap: nowrap;
        background-color: $primaryColor;
        color: white;
        text-align: center;
        z-index: 0;
        font-weight: 500;
      }
      tbody {
        tr {
          td {
            p {
              margin: 0;
              text-transform: capitalize;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
