@import "../../Common/commonStyle/commonStyle.scss";
.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  img {
    width: 8rem;
  }
}
.approve {
  background-color: $green;
}
.reject {
  background-color: $red;
}
