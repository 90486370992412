@import "../../Common/commonStyle/commonStyle.scss";
.view-request-container {
  max-height: 24rem;
  overflow-y: auto;
  p {
    margin: 0;
    margin-left: 1rem;
  }
  h4 {
    margin: 0;
  }
  .detail {
    margin-top: 0.5rem;
    align-items: center;
  }
  .card {
    background-color: $lightGrey;
    border: 1px solid $grey;
    border-radius: 5px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    p {
      color: $grey;
      margin: 0;
    }
    button {
      width: 100%;
    }
  }
}
