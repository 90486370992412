@import "../../Common/commonStyle/commonStyle.scss";
.notification-setting-container {
  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .notify {
    font-weight: 400;
    margin: 0.5rem;
  }
  .notification-item {
    p {
      margin-top: 0.6rem;
      margin-bottom: 0rem;
    }
    span {
      font-size: 12px;
    }
  }
}
